
import { useForm } from "react-hook-form";
import { useState } from "react";
import Input from "./Input";
import configData from "../config.json";
import images from '../images';

const LoginForm = (props) => {

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const onSubmit = (data) => {
        console.log(data);
        props.ParentSubmit(data);
    };

    let logoBas = images[configData.LOGOIMAGE];

    const accountComponent = (
        <Input
            register={register}
            name="account"
            errors={errors}
            validation={{
                required: true,
                minLength: 1,
                maxLength: 30,
                pattern: /^[^\d]+$/,
            }}
            placeholder="Account Number"
            message=""
        />
    );

    const passwordComponent = (
        <div style={{ position: "relative", display: "inline-block" }}>
            <Input
                register={register}
                type={showPassword ? "text" : "password"}
                name="password"
                errors={errors}
                validation={{
                    required: true,
                    minLength: 1,
                    maxLength: 30,
                }}
                placeholder="Password"
                message=""
            />
            <img
                src={
                    showPassword ? images.see : images.notSee
                }
                alt="toggle visibility"
                className="see"
                onClick={() => setShowPassword(!showPassword)}
            />
        </div>
    );

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="login-form">
                {accountComponent}
                {passwordComponent}
                <p className="forgot">Forgot your login info?</p>
                <input type="submit" value="LOGIN" className="sign-button" />
            </form>
        </>
    );
};

export default LoginForm;