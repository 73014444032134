import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './scss/app.scss';

export default class App extends Component {
  static displayName = App.name;

    render() {
    return (
        <Layout>
            <input type="hidden" name="ioBlackBox" id="ioBlackBox" />
            <input type="hidden" name="visitorID" id="visitorID" />
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
            </Routes>
        </Layout>
    );
  }
}
