import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";

const Input = ({
    type = "text",
    className = "input-register",
    register,
    name,
    errors,
    validation,
    placeholder,
    message,
    maxLength = Infinity,
    onChange,
    onBlur,
}) => {

    function getClassName() {
        if (className === "checkbox") {
            return className;
        }
        if (errors && errors[name]) {
            return "input-error";
        }
        return className;
    }

    return (
        <>
            {message != "" ? (
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip className={errors && errors[name] ? "error" : ""} id="tooltip">
                            {message}
                        </Tooltip>
                    }
                >
                    <input
                        className={getClassName()}
                        {...register(name, {
                            ...validation,
                            onChange: (e) => {
                                if (onChange) {
                                    onChange(e);
                                }
                                validation.onChange && validation.onChange(e);
                            },
                            onBlur: (e) => {
                                if (onBlur) {
                                    onBlur(e);
                                }
                            },
                        })}
                        /*onBlur={(e) => {
                            onBlur(e);
                        }}*/
                        type={type}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        id={name}
                    />
                </OverlayTrigger>
            ) : (
                    <input
                        className={getClassName()}
                        {...register(name, {
                            ...validation,
                            onChange: (e) => {
                                if (onChange) {
                                    onChange(e);
                                }
                                validation.onChange && validation.onChange(e);
                            },
                            onBlur: (e) => {
                                if (onBlur) {
                                    onBlur(e);
                                }
                            },
                        })}
                        /*onBlur={(e) => {
                            onBlur(e);
                        }}*/
                        type={type}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        id={name}
                    />
                    )}
        </>
    );
};

Input.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    className: PropTypes.string,
    register: PropTypes.func.isRequired,
    validation: PropTypes.object.isRequired,
    errors: PropTypes.object,
    placeholder: PropTypes.string,
    message: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

export default Input;
