import React, { Component } from 'react';
import { Button, Card, Col, Form, Row, InputGroup, Spinner, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Header } from "./Header";
import AuthService from "../services/auth.service";
import { BetNowLink } from './LinkButton'
import ReactDOM from 'react-dom';
import configData from "../config.json";
import ReCAPTCHA from 'react-google-recaptcha';
import SignupForm from "./SignupForm"; 
import images from '../images';

import { LoginSocialFacebook, LoginSocialGoogle, LoginSocialTwitter } from 'reactjs-social-login'
import { FacebookLoginButton, GoogleLoginButton, TwitterLoginButton } from 'react-social-login-buttons'

export class Signup extends Component {
    static displayName = Signup.name;

    constructor(props) {
        super(props);

        //Get received params
        const query = new URLSearchParams(window.location.search.substring(1));
        let agentID = query.get('affid');
        if (agentID == null) {
            agentID = query.get('pub_id');
        } 
        if (agentID == null) {
            agentID = query.get('publisher_id');
        }

        if (agentID == null) {
            agentID = "";
        }

        /*let promoCode = query.get('tokenLink');
        if (promoCode == null) {
            promoCode = "";
        }*/

        let affiliateID = "";

        //Used to find Affiliate ID - from MyAffiliates link
        let token = query.get('token');
        if (token == null) {
            token = "";
        }

        //console.log("AgentID: " + agentID + " Token: " + token);

        this.state = {
             
            isValidEmail: null,
            invalidEmailMessage: '',
            isValidPhone: null,
            invalidPhoneMessage: '',

            agentID : agentID,
            promoCode: '',
            token: token,
            affiliateID: affiliateID,
            visitorID: "",
            //ioBlackbox: "",

            showPassword: false,
            showPIN: false,

            signupStep: 'collect', //steps: collect, submit, complete
            signupResult: null,
            signupMessage: "",

            //fullyloaded: false,
            //useFacebook: false,
            //facebookResponse: {},

            /*signupStep: 'complete', //steps: collect, submit, complete
            signupResult: true,*/

            isValidReCaptcha: null,
            recaptcha: null,
            ipAddress: "",
        };


        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateReChaptcha = this.validateReChaptcha.bind(this);

        //this.handleShowPass = this.handleShowPass.bind(this);
        //this.validateInput = this.validateInput.bind(this);
        this.onChange = this.onChange.bind(this);

        this.onResolveSocial = this.onResolveSocial.bind(this);
        this.onRejectSocial = this.onRejectSocial.bind(this);
    }

    componentDidMount() {
        AuthService.getClientIP().then((result) => {
            //console.log('ip:' + result.ip);
            //this.setState({ fullyloaded: true, ipAddress:result.ip });
            this.setState({ ipAddress: result.ip });
        });
    }


    validateReChaptcha() {

        //  ('validateReChaptcha ' + configData.GOOGLE.ENABLE_RECAPTCHA);

        if (!configData.GOOGLE.ENABLE_RECAPTCHA) {
            this.setState({ isValidReCaptcha: true });
            return true;
        }
        else {

            //console.log('validateReChaptcha paso...');

            let isValid = (this.state.recaptcha != null);
            //console.log("CAPTCHA 2 value: " + this.state.therecaptcha);
            //console.log("CAPTCHA valid: " + isValid);
            this.setState({ isValidReCaptcha: isValid });
        }
    }

    handleSubmit(data) {

        //console.log('handleSubmit :' + JSON.stringify(data));

        //if (this.state.isValidEmail &&
        //    this.state.isValidPhone) {
            try {
                this.setState({ signupStep: 'submit' });

                AuthService.signup(data.firstName,
                    data.lastName,
                    data.Password,
                    data.Pin,
                    data.Email,
                    data.phoneNumber,
                    this.state.agentID,
                    data.promoCode,
                    this.state.token,
                    "en",
                    data.termsConditions,
                    data.marketingPromotions,
                    "local",
                    "",
                    this.state.ipAddress,
                    this.state.affiliateID
                ).then((result) => {
                        //console.log('Response: ' + JSON.stringify(result));
                        let message = "";
                        if (result.created != true) {
                            message = result.error;
                        }

                        this.setState({ signupStep: 'complete', signupResult: result.created, signupMessage: message });
                        // alert('Signup !!!');
                    }, (error) => {
                        this.setState({ signupStep: 'complete', signupResult: false, signupMessage: error });
                    });
            }
            catch (ex) {
                this.setState({ signupStep: 'complete', signupResult: false, signupMessage: ex });
            }
        //}


        //TODO: remove all below this point...
        return;
    }

    onResolveSocial(response) {

        //console.log('onResolveSocial...');
        //console.log( response.provider);
        //console.log( response.data );

        //return;

        let authUser = {
            firstname: "",
            lastname: "",
            email: "",
            token: "",
            provider: response.provider
        };

        if (response.provider == 'facebook') {
            authUser.firstname = response.data.first_name;
            authUser.lastname = response.data.last_name;
            authUser.email = response.data.email;
            authUser.token = response.data.accessToken;
        }else if (response.provider == 'google') {
            authUser.firstname = response.data.given_name;
            authUser.lastname = response.data.family_name;
            authUser.email = response.data.email;
            authUser.token = response.data.access_token;
        } else {
            return;
        }

        //console.log(authUser);
        //return;

        if (authUser.email == "") {
            //this.setState({ signupStep: 'complete', signupResult: false, signupMessage: "Invalid Facebook response!" });
            return;
        }

        this.setState({ signupStep: 'submit' });

        AuthService.validateData(authUser.email, '', 1).then((responseValidate) => {
            if (responseValidate.success == true) {
                if (responseValidate.isValid == true) {
                    try {
                        //this.setState({ signupStep: 'submit' });
                        AuthService.signup(authUser.firstname,
                            authUser.lastname,
                            '', //pass
                            '', //pin
                            authUser.email,
                            '',
                            this.state.agentID,
                            this.state.promoCode,
                            this.state.token,
                            "en",
                            true,
                            false,
                            authUser.provider,
                            authUser.token,
                            this.state.ipAddress,
                            this.state.affiliateID).then((result) => {
                                //console.log('Response: ' + JSON.stringify(result));
                                let message = "";
                                if (result.created != true) {
                                    message = result.error;
                                }

                                this.setState({ signupStep: 'complete', signupResult: result.created, signupMessage: message });
                                // alert('Signup !!!');
                            }, (error) => {
                                this.setState({ signupStep: 'complete', signupResult: false, signupMessage: error });
                            });
                    }
                    catch (ex) {
                        this.setState({ signupStep: 'complete', signupResult: false, signupMessage: ex });
                    }

                } else {
                    this.setState({ signupStep: 'complete', signupResult: false, signupMessage: "Email is already registered, please use a different email or contact Customer Services" });
                }
            } else {
                //console.log("ERROR: validating email!");
                this.setState({ signupStep: 'complete', signupResult: false, signupMessage: "Error validating email, please contact Customer Services" });
            }
        });
        
    }

    onRejectSocial(err) {
        //console.log('failure...');
        //console.log(err);
    }

    refreshPage() {
        window.location.reload();
    }

    onChange(value) {
        if (configData.GOOGLE.ENABLE_RECAPTCHA) {
            //console.log('Captcha received!!!');
            //console.log('Captcha value: ' + value);
            let isValid = (value != null);
            //let captchaValue = null;
            //console.log('Captcha valid: ' + isValid);

            //if (isValid) {
            //    captchaValue = "xxxx";
            //}

            this.setState({ recaptcha: value, isValidReCaptcha: isValid });
        }
    }

    render() {

        let logoBas = images[configData.LOGOIMAGE];
        let errorImg = images.errorImg;
        let verificationImg = images.verificationImg;

      return (
          <div>
              {this.state.signupStep == "collect" ? (

                  <div className="register-page">
                      <div className="top-container">
                          <a href={configData.WebsiteURL + (this.state.agentID != "" ? "?affid=" + this.state.agentID: "")}>
                              <img src={logoBas} className="logo-bas" />
                          </a>
                          <h1 className="title">Signup</h1>
                          <p className="info">Please fill out the registration form</p>
                          <p className="required">All fields are required</p>
                      </div>
                      <SignupForm ParentSubmit={this.handleSubmit} AffID={this.state.agentID}></SignupForm>
                      <p className="logIn">
                          Already a member?{" "}
                          <a className="strong" href={configData.WebsiteURL + "login" + (this.state.agentID != "" ? "?affid=" + this.state.agentID : "")}>
                              Log in
                          </a>
                      </p>
                  </div>

              ): ("")}
              {this.state.signupStep == "submit" ? (
                  <div className="submit-page">
                      <a href={configData.WebsiteURL + (this.state.agentID != "" ? "?affid=" + this.state.agentID : "")} >
                        <img src={logoBas} className="logo-bas" />
                      </a>
                      <Spinner animation="border" size="lg" >
                          <span className="visually-hidden">Loading...</span>
                      </Spinner>

                        <h1 className="tittle">Creating Account...</h1>
                    </div>

                ) : ("")}
              {this.state.signupStep == "complete" ? (
                  <>
                          {this.state.signupResult == true ? (
                          <div className="verification-page">
                              <a href={configData.WebsiteURL + (this.state.agentID != "" ? "?affid=" + this.state.agentID : "")}>
                                  <img src={logoBas} className="logo-bas" />
                              </a>
                                  <img src={verificationImg} className="verification-img" />
                                  <h1 className="tittle">Pending Verification</h1>
                                  <p className="info">Your BAS account is almost ready.</p>
                                  <p className="info">
                                      Please check your inbox for the verification email.
                                  </p>
                              <BetNowLink customerID="" text="CONTINUE"></BetNowLink>
                              </div>
                          ) : (
                              <div className="not-added-page">
                                  <a href={configData.WebsiteURL + (this.state.agentID != "" ? "?affid=" + this.state.agentID : "")}>
                                      <img src={logoBas} className="logo-bas" />
                                  </a>
                            <img src={errorImg} className="error-img" /> 
                            <h1 className="tittle">Record Not Added</h1>
                            <div className="content-info">
                              <p className="info">Your information matches an existing account.</p>
                              <p className="info">
                                Please contact Customer Service for assistance or
                              </p>
                              <p className="info"> call us at 1-800-604-5922, we are open 24/7.</p>
                            </div>
                                  <button className="support-button"
                                  onClick={() => { window.adaEmbed.toggle(); }}>CONTACT SUPPORT</button>
                                  <button className="go-back-button"
                                  onClick={() => this.refreshPage()}>Go Back</button>
                          </div>
                          )}
                  </>  
              ) : ("")}
        </div>

    );
  }
}
