import React, { Component } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Header } from "./Header";
import AuthService from "../services/auth.service";
import { BetNowLink } from './LinkButton'
//import successImage from '../img/success.png'
//import failureImage from '../img/failure.png'
import configData from "../config.json";
import images from '../images';

export class Verify extends Component {
    static displayName = Verify.name;

    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search.substring(1));
        let key = query.get('key');

        this.state = {
            visitorID: "",
            ioBlackbox: "",
            key: key,
            verifyStep: "submit", //submit , complete
            verifyResult: null,
            verifyMessage: "",
            customerID: "",
            ipAddress: "",

            //verifyStep: "complete", //submit , complete
            //verifyResult: true,
        };

        //console.log("key: " + key);

        this.checkioBlackbox = this.checkioBlackbox.bind(this);
    }

    componentDidMount() {

        AuthService.getClientIP().then((result) => {
            //console.log('ip:' + result.ip);
            this.setState({ ipAddress: result.ip });
        });

        window.addEventListener('FingerprintJS:visitorId', (pEvent) => {
            if (pEvent['detail']) {
                let visitorID = pEvent['detail']['visitorId'].toString();
                //console.log('VisitorID: ' + visitorID);
                this.setState({ visitorID: visitorID });
            }
        });

        //let ioBlackbox = window.document.getElementById("ioBlackBox").value;
        //console.log('ioBlackbox value: ' + ioBlackbox);
        //console.log(window.document.getElementById("ioBlackBox"));
        //this.setState({ ioBlackbox: ioBlackbox });

        this.checkioBlackbox();
    }

    checkioBlackbox() {
        //return;
        let ioBlackbox = window.document.getElementById("ioBlackBox").value;
        if (ioBlackbox == "") {
            //console.log('iovation waiting...');
            window.setTimeout(this.checkioBlackbox, 2000);
        } else {
            //console.log('iovation received: ' + ioBlackbox);

            if (this.state.key != null && this.state.key != '') {
                try {
                    AuthService.verifyEmail(this.state.key,
                        ioBlackbox,
                        this.state.visitorID,
                        this.state.ipAddress
                        ).then((response) => {
                        if (response.success) {

                            const script = document.createElement("script");
                            script.src = "./js/ZetaGlobal/registration.js"
                            document.body.appendChild(script);

                            this.setState({ verifyStep: 'complete', verifyResult: true, verifyMessage: "", customerID: response.customerID });
                        } else {
                            if ((response.error == 'email already exists') || (response.error == 'phone already exists')) {
                                response.error = 'Your information matches an existing account. Please contact Customer Service for assistance by clicking the button below or call us at <span className="noWrap">1-800-604-5922</span>, we are open 24/7.';
                                
                                //Please contact Customer Service for assistance by clicking the button below or call us at < span className = "noWrap" > 1 - 800 - 604 - 5922</span >, we are open 24 / 7.';
                            }

                            this.setState({ verifyStep: 'complete', verifyResult: false, verifyMessage: 'There was an error while creating your account. ' + response.error });
                        }
                    }, (error) => {
                        this.setState({ verifyStep: 'complete', verifyResult: false, verifyMessage: 'There was an error while creating your account. ' + error });
                    });
                } catch (error) {
                    this.setState({ verifyStep: 'complete', verifyResult: false, verifyMessage: 'There was an error while creating your account. ' + error });
                }
            }
            else {
                this.setState({ verifyStep: 'complete', verifyResult: false, verifyMessage: "Invalid key received!" });
            }
        }
    }

    render() {

        let logoBas = images[configData.LOGOIMAGE];
        let errorImg = images.errorImg;
        let successImg = images.successImg;

      return (
          <>
              {this.state.verifyStep == "submit" ? (

                  <div className="submit-page">
                      <a href={configData.WebsiteURL}>
                        <img src={logoBas} className="logo-bas" />
                      </a>
                      <Spinner animation="border" size="lg" >
                          <span className="visually-hidden">Loading...</span>
                      </Spinner>

                      <h1 className="tittle">Creating Account...</h1>
                  </div>

              ) : ("")}
              {this.state.verifyStep == "complete" ? (
                  <>
                              {this.state.verifyResult == true ? (
                          <>
                              <div className="succcess-page">
                                  <a href={configData.WebsiteURL}>
                                      <img src={logoBas} className="logo-bas" />
                                  </a>
                                  <img src={successImg} className="success-img" />
                                  <h1 className="tittle">Verification Success</h1>
                                  <div className="content-info">
                                      <p className="info">Your account</p>
                                      <p className="info">{this.state.customerID}</p>
                                      <p className="info">has been created!</p>
                                  </div>
                                  <BetNowLink customerID={this.state.customerID} text="BET NOW"></BetNowLink>
                              </div>

                                  </>
                              ) : ("")}
                              {this.state.verifyResult == false ? (
                                  <>
                              <div className="technical-error-page">
                                  <a href={configData.WebsiteURL}>
                                      <img src={logoBas} className="logo-bas" />
                                  </a>
                                          <img src={errorImg} className="error-img" />
                                          <h1 className="tittle">Error</h1>
                                          <div className="content-info">
                                              <p className="info">Your information matches an existing account.</p>
                                              <p className="info">Please contact Customer Service for assistant or</p>
                                              <p className="info"> call us at 1-800-604-5922, we are open 24/7.</p>
                                          </div>
                                          <button className="support-button" onClick={() => { window.adaEmbed.toggle(); }}>CONTACT SUPPORT</button>
                                      </div>

                                  </>
                              ) : ("")}

                  </>
              ) : ("")} 
          </>
    );
  }
}
