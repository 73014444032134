import React, { Component } from 'react';
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import configData from "../config.json";
import images from '../images';

export class Header extends Component {
  static displayName = Header.name;

  constructor(props) {
      super(props);
      this.title = props.title;
  }

    render() {

        let Logo = images[configData.LOGOIMAGE];

    return (
        <Card.Header>
            <Link to="/" className="header-logo mb-4 logo">
                <img src={Logo} ></img>
            </Link>
            <Card.Title><span className="spanTitle">{this.title}</span></Card.Title>
            <Card.Text></Card.Text>
        </Card.Header>
    );
  }
}
