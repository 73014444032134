
import { useForm } from "react-hook-form";
import { useState } from "react";
import Input from "./Input";
import configData from "../config.json";
import images from '../images';

const SignupForm = (props) => {

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const [showPin, setShowPin] = useState(false);
    const onSubmit = (data) => {
        console.log(data);
        props.ParentSubmit(data);
    };

    let affID = props.AffID;

    let logoBas = images[configData.LOGOIMAGE];

    const firstnameComponent = (
        <Input
            register={register}
            name="firstName"
            errors={errors}
            validation={{
                required: true,
                minLength: 1,
                maxLength: 30,
                pattern: /^[^\d]+$/,
            }}
            placeholder="First Name"
            message="Must be the same as your legal ID"
        />
    );

    const lastnameComponent = (
        <Input
            register={register}
            name="lastName"
            errors={errors}
            validation={{
                required: true,
                minLength: 1,
                maxLength: 30,
                pattern: /^[^\d]+$/,
            }}
            placeholder="Last Name"
            message="Must be the same as your legal ID"
        />
    );

    const passwordValidation = {
        required: true,
        minLength: 7,
        maxLength: 50,
        pattern:
            /^(?=(?:[^a-zA-Z]*[a-zA-Z]){4})(?=(?:[^0-9]*[0-9]){2})(?=.*[\W_]).{7,50}$/,
    };


    //Viejo
    ///^(?=.*[A-Za-z]{4,})(?=.*\d{2,})(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?]).*$/
    //Nuevo
    //^(?=(?:[^a-zA-Z]*[a-zA-Z]){4})(?=(.*\d.*\d))(?=.*[\W_]).{7,50}$
    //Nuevo 2
    //^(?=(?:[^a-zA-Z]*[a-zA-Z]){4})(?=(?:[^0-9]*[0-9]){2})(?=.*[\W_]).{7,50}$

    const emailValidation = {
        required: true,
        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    };

    const emailComponent = (
        <Input
            register={register}
            name="Email"
            type="email"
            errors={errors}
            validation={emailValidation}
            onChange={(e) => {
                /*const value = e.target.value;
                if (!emailValidation.pattern.test(value)) {
                    setError("Email", {
                        type: "pattern",
                    });
                } else {
                    clearErrors("Email");
                }*/
            }}
            placeholder="Email Address"
            message="Must be a valid email address"
            onBlur={(e) => {
                const value = e.target.value;
                if (!emailValidation.pattern.test(value) && value != "") {
                    setError("Email", {
                        type: "pattern",
                    });
                } else {
                    clearErrors("Email");
                }
            }}
        />
    );

    const phoneComponent = (
        <Input
            register={register}
            type="tel"
            name="phoneNumber"
            errors={errors}
            maxLength={14}
            validation={{
                required: true,
                minLength: 14,
                maxLength: 14,
                //pattern: /^[0-9]{10}$/,
            }}
            placeholder="Phone Number"
            message="A 10-digit phone number is required"
            onChange={(e) => {
                /*const value = e.target.value;
                if (!/^[0-9]*$/.test(value)) {
                    e.target.value = value.slice(0, -1);
                }*/

                let phoneNumber = e.target.value.replace(/\D/g, '');
                let matchedPhone = phoneNumber.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                let masked = "";
                if (matchedPhone[1] != '') {
                    masked = '(' + matchedPhone[1];
                }
                if (matchedPhone[2] != '') {
                    masked += ') ' + matchedPhone[2];
                }
                if (matchedPhone[3] != '') {
                    masked += '-' + matchedPhone[3];
                }
                e.target.value = masked;

                /*if (/^[0-9]{10}$/.test(phoneNumber)) {
                    clearErrors("phoneNumber");
                } else {
                    setError("phoneNumber", {
                        type: "pattern",
                    });
                }*/

            }}
            onBlur={(e) => {
                let phoneNumber = e.target.value.replace(/\D/g, '');
                /*let matchedPhone = phoneNumber.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                let masked = "";
                if (matchedPhone[1] != '') {
                    masked = '(' + matchedPhone[1];
                }
                if (matchedPhone[2] != '') {
                    masked += ') ' + matchedPhone[2];
                }
                if (matchedPhone[3] != '') {
                    masked += '-' + matchedPhone[3];
                }
                e.target.value = masked;*/

                if (/^[0-9]{10}$/.test(phoneNumber)) {
                    clearErrors("phoneNumber");
                } else if (phoneNumber != "") {
                    setError("phoneNumber", {
                        type: "pattern",
                    });
                } else {
                    clearErrors("phoneNumber");
                }
            }}
        />
    );

    const pinComponent = (
        <div style={{ position: "relative", display: "inline-block" }}>
            <Input
                register={register}
                name="Pin"
                type={showPin ? "text" : "password"}
                errors={errors}
                maxLength={4}
                validation={{
                    required: true,
                    minLength: 4,
                    maxLength: 4,
                    pattern: /^[0-9]{4}$/,
                }}
                placeholder="Pin"
                message="PIN must have 4 digits"
                onChange={(e) => {
                    const value = e.target.value;
                    if (!/^[0-9]*$/.test(value)) {
                        e.target.value = value.slice(0, -1);
                    } /*else if (/^[0-9]{4}$/.test(value)) {
                        clearErrors("Pin");
                    } else {
                        setError("Pin", {
                            type: "pattern",
                        });
                    }*/
                }}
                onBlur={(e) => {
                    const value = e.target.value;
                    if (/^[0-9]{4}$/.test(value)) {
                        clearErrors("Pin");
                    } else if (value != "") {
                        setError("Pin", {
                            type: "pattern",
                        });
                    } else {
                        clearErrors("Pin");
                    }
                }}
            />
            <img
                src={
                    showPin ? images.see : images.notSee
                }
                alt="toggle visibility"
                className="see"
                onClick={() => setShowPin(!showPin)}
            />
        </div>
    );

    //^(?=.*[A-Za-z]{4,})(?=.*\d{2,})(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>\/?]).*$
    //Nuevo
    //^(?=(?:[^a-zA-Z]*[a-zA-Z]){4})(?=(?:[^0-9]*[0-9]){2})(?=.*[\W_]).{7,50}$

    const passwordComponent = (
        <div style={{ position: "relative", display: "inline-block" }}>
            <Input
                register={register}
                name="Password"
                type={showPassword ? "text" : "password"}
                errors={errors}
                validation={{
                    required: true,
                    minLength: 7,
                    maxLength: 50,
                    pattern:
                        /^(?=(?:[^a-zA-Z]*[a-zA-Z]){4})(?=(?:[^0-9]*[0-9]){2})(?=.*[\W_]).{7,50}$/,
                }}
                placeholder="Password"
                message="Password must be between 7 and 50 characters long. It must have at least 4 letters, 2 numbers, and 1 special character."
                onChange={(e) => {
                    /*const value = e.target.value;
                    if (!passwordValidation.pattern.test(value)) {
                        setError("Password", {
                            type: "pattern",
                        });
                    } else {
                        clearErrors("Password");
                    }*/
                }}
                onBlur={(e) => {
                    const value = e.target.value;
                    if (!passwordValidation.pattern.test(value) && value != "") {
                        setError("Password", {
                            type: "pattern",
                        });
                    } else {
                        clearErrors("Password");
                    }
                }}
            />
            <img
                src={
                    showPassword ? images.see : images.notSee
                }
                alt="toggle visibility"
                className="see"
                onClick={() => setShowPassword(!showPassword)}
            />
        </div>
    );

    const promoCode = (
        <Input
            register={register}
            name="promoCode"
            errors={errors}
            validation={{ required: false }}
            placeholder="Promo Code (Optional)"
            message="Affiliate and agent referrals only."
        />
    );

    const termandconditionsComponent = (
        <div className={errors.termsandConditions ? "validations-error" : "validations"}>
            <Input
                register={register}
                className="checkbox"
                errors={errors}
                type="checkbox"
                name="termsandConditions"
                validation={{ required: true }}
                message="You need to agree to the Terms & Conditions"
            />
            <label className="label-register" htmlFor="termsandConditions">
                I am over 21 years old and agree to the <a href={"https://betanysports.eu/rules" + (affID != "" ? "?affid=" + affID : "")} target="_blank">Terms & Conditions</a>
            </label>
        </div>
    );

    const marketingComponent = (
        <div className={errors.marketingPromotions ? "validations-error" : "validations"}>
            <Input
                register={register}
                className="checkbox"
                errors={errors}
                type="checkbox"
                name="marketingPromotions"
                validation={{ required: false }}
                message={""}
            />
            <label className="label-register" htmlFor="marketingPromotions">
                I agree to receive marketing and promotions from BAS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </label>
        </div>
    );

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="register-form">
                {firstnameComponent}
                {lastnameComponent}
                {emailComponent}
                {phoneComponent}
                {pinComponent}
                {passwordComponent}
                {promoCode}
                {termandconditionsComponent}
                {marketingComponent}
                <input type="submit" value="SIGN UP" className="sign-button" />
            </form>
        </>
    );
};

export default SignupForm;