import React, { Component } from 'react';
import { Button, Card, Col, Form, Row, InputGroup, Alert, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
//import { Header } from "./Header";
import AuthService from "../services/auth.service";
import configData from "../config.json";
import LoginForm from "./LoginForm"; 
import images from '../images';

import { LoginSocialFacebook, LoginSocialGoogle, LoginSocialTwitter } from 'reactjs-social-login'
import { FacebookLoginButton, GoogleLoginButton, TwitterLoginButton } from 'react-social-login-buttons'

export class Signin extends Component {
    static displayName = Signin.name;

    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search.substring(1));

        let username = ""; 
        if (query.get('cid') != null) {
            username = query.get('cid');
        } 

        this.state = {
            //username: username,
            //password: "",
            hash: "",
            redirectURL: "",
            //isValidUsername: null,
            //isValidPassword: null,
            //showPassword: false,
            errorMessage: "",
            authError: false,
            loading: false,
            loadingFacebook: false,
            loadingGoogle: false,
            ipAddress: "",
        }

        //this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        //this.validatePassword = this.validatePassword.bind(this);
        //this.handleShowPass = this.handleShowPass.bind(this);
        //this.validateInput = this.validateInput.bind(this);

        this.onResolveSocial = this.onResolveSocial.bind(this);
        this.onRejectSocial = this.onRejectSocial.bind(this);

        //this.username = React.createRef();
        //this.password = React.createRef();
    }

    componentDidMount() {
        AuthService.getClientIP().then((result) => {
            //console.log('ip:' + result.ip);
            this.setState({ ipAddress: result.ip });
        });
    }

    /*handleChange(event) {
        //console.log('trigger handleChange for: ' + event.target.id);
        switch (event.target.id) {
            case "Username":
                this.setState({ username: event.target.value });
                break;
            case "Password":
                this.setState({ password: event.target.value });
                break;
        }
    }*/

    handleSubmit(event) {
        event.preventDefault();
        //console.log('trigger handleSubmit');

        this.setState({
            errorMessage: "",
            authError: false,
            loading:true
        });

        //this.validateInput('Username', 1, 30, true);
        //this.validateInput('Password', 1, 30, true);

        //if (this.state.isValidUsername &&
        //    this.state.isValidPassword) {

            AuthService.login(this.state.username,
                this.state.password,
                "local",
                "",
                this.state.ipAddress).then((result) => {
                    //console.log('Response: ' + JSON.stringify(result));

                    if (result.success != true) {
                        //alert(result.error);
                        this.setState({
                            errorMessage: result.error,
                            authError: true,
                            loading: false
                        });
                    } else {
                        this.setState({ redirectURL: result.redirectURL}, () => {
                            //console.log("Redirecting...");
                            const redirectForm = document.getElementById("redirectForm");
                            redirectForm.submit();
                        })
                    }
                    // alert('Signup !!!');
                }, (error) => {
                    this.setState({ authError: true, errorMessage: error,loading: false });
                });
        //}
        //else {
        //    this.setState({loading: false});
        //}
    }


    onResolveSocial(response) {

        //console.log('onResolveSocial...');
        //console.log(response.provider);
        //console.log(response.data);

        let authUser = {
            email: "",
            token: "",
            provider: response.provider 
        };

        if (response.provider == 'facebook') {
            authUser.email = response.data.email;
            authUser.token = response.data.accessToken;
        } else if (response.provider == 'google') {
            authUser.email = response.data.email;
            authUser.token = response.data.access_token;
        }
        else {
            return;
        }

        //console.log(authUser);

        if (authUser.email == "") {
            //this.setState({ signupStep: 'complete', signupResult: false, signupMessage: "Invalid Facebook response!" });
            return;
        }

        if (response.provider == "facebook") {
            this.setState({ loadingFacebook: true });
        } else if (response.provider == "google") {
            this.setState({ loadingGoogle: true });
        }

        this.setState({
            errorMessage: "",
            authError: false
        });

        AuthService.login(authUser.email,
            "",
            authUser.provider,
            authUser.token,
            this.state.ipAddress).then((result) => {
                //console.log('Response: ' + JSON.stringify(result));

            if (result.success != true) {
                //alert(result.error);

                if (response.provider == "facebook") {
                    this.setState({ loadingFacebook: false });
                } else if (response.provider == "google") {
                    this.setState({ loadingGoogle: false });
                }

                this.setState({
                    errorMessage: result.error,
                    authError: true
                });

            } else {
                this.setState({ redirectURL: result.redirectURL, username: result.username, password: "", hash: result.hash }, () => {
                    //console.log("Redirecting...");
                    const redirectForm = document.getElementById("redirectForm");
                    redirectForm.submit();
                })
            }
            // alert('Signup !!!');
        }, (error) => {

            if (response.provider == "facebook") {
                this.setState({ loadingFacebook: false });
            } else if (response.provider == "google") {
                this.setState({ loadingGoogle: false });
            }

            this.setState({ authError: true, errorMessage: error });
        });

    }

    onRejectSocial(err) {
        //console.log('failure...');
        //console.log(err);

        if (err.provider == "facebook") {
            this.setState({ loadingFacebook: false });
        } else if (err.provider == "google") {
            this.setState({ loadingGoogle: false });
        }
            
    }

    render() {

        let logoBas = images[configData.LOGOIMAGE];

        return (
            <>
                <div>
                    <div className="login-page">
                        <div className="top-container">
                            <a href={configData.WebsiteURL}>
                                <img src={logoBas} className="logo-bas" />
                            </a>
                            <h1 className="tittle">Welcome back!</h1>
                            <p className="info">Please log in to continue</p>
                        </div>
                        <Alert variant="danger" className={(this.state.authError == true) ? "" : "hide"}>
                            {this.state.errorMessage}
                        </Alert>
                        <LoginForm ParentSubmit={this.handleSubmit}></LoginForm>

                        <p className="info info-new">New to BetAnySports?</p>
                        <Link className="signup-button" to="/signup">SIGN UP</Link>
                    </div>
                </div>

                <div className="hide">
                    <form id="redirectForm" action={this.state.redirectURL} method="POST">
                        <input name="customerID1" type="hidden" value={this.state.username} ></input>
                        <input name="password1" type="hidden" value={this.state.password} ></input>
                        <input name="key" type="hidden" value="" ></input>
                        <input name="sct" type="hidden" value={this.state.hash} ></input>
                    </form>
                </div>

            </>
        
    );
  }
}
