
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import configData from "../config.json";

export function BetNowLink (props){

    const navigate = useNavigate();

    return (

        <button className="continue-button"
            onClick={() => window.location.replace(configData.WebsiteURL)}>{props.text}</button>
    );
};
