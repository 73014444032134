import axios from "axios";
import configData from "../config.json";

const APIURL = configData.APIURL;

class AuthService {

    validateData(email, phone, validateType) {
        const config = {
            method: "POST",
            url: "auth/validate",
            data: {
                "validateType": validateType,
                "email": email,
                "phone" : phone
            },
            headers: {
                "Content-Type": "application/json"
            }
        };

        console.log("API call: " + JSON.stringify(config));

        return axios.request(config).then(function (response) {
            console.log("response validate: " + validateType + " - " + JSON.stringify(response.data));
            return response.data;
        });
    }

    validateDataTest (email, phone, validateType) {
        const config = {
            method: "GET",
            url: "auth/validate",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };

        //console.log("API call: " + JSON.stringify(config));

        return axios.request(config).then(function (response) {
            return response.data;
        });
    }

    signup(firstname, lastname, password, pin, email, phone, agentID, promoCode, token, lang, acceptTermConditions, acceptReceiveMarketing, authProvider, authToken, originatedIP, affiliateID) {

        const config = {
            method: "POST",
            url: "auth/signup",
            data: {
                "firstname": firstname,
                "lastname": lastname,
                "password": password,
                "email": email, 
                "phone": phone,
                "agentID": agentID,
                "affiliateID": affiliateID,
                "promoCode": promoCode,
                "token": token,
                "pin": pin,
                "lang": lang,
                "acceptTermConditions": acceptTermConditions,
                "acceptReceiveMarketing": acceptReceiveMarketing,
                //"ioBlackbox": ioBlackbox,
                //"visitorID": visitorID,
                "authProvider": authProvider,
                "authToken": authToken,
                "originatedIP": originatedIP
            },
            headers: {
                "Content-Type": "application/json"
            }
        };

        //console.log("API call: " + JSON.stringify(config));

        return axios.request(config).then(function (response) {
            return response.data;
        });
    }

    verifyEmail(key, ioBlackbox, visitorID, originatedIP) {
        const config = {
            method: "POST",
            url: "auth/verifyEmail",
            data: {
                "key": key,
                "ioBlackBox": ioBlackbox,
                "fingerprint": visitorID,
                "originatedIP": originatedIP
            },
            headers: {
                "Content-Type": "application/json"
            }
        };

        //console.log("API call: " + JSON.stringify(config));

        return axios.request(config).then(function (response) {
            return response.data;
        });
    }

    login(username, password, authProvider, authToken, originatedIP) {
        const config = {
            method: "POST",
            url: "auth/login",
            data: {
                "username": username,
                "password": password,
                "authProvider": authProvider,
                "authToken": authToken,
                "originatedIP": originatedIP
            },
            headers: {
                "Content-Type": "application/json"
            }
        };

        //console.log("API call: " + JSON.stringify(config));

        return axios.request(config).then(function (response) {
            return response.data;
        });
    }

    getClientIP() {
        const config = {
            method: "GET",
            url: "https://api.ipify.org/?format=json"
        };

        //console.log("API getClientIP call: " + JSON.stringify(config));

        return axios.request(config).then(function (response) {
            //console.log("API response: " + JSON.stringify(response));
            return response.data;
        });
        
    }

}

export default new AuthService();