import { Signup } from "./components/Signup";
import { Signin } from "./components/Signin";
import { Verify } from "./components/Verify";
 
const AppRoutes = [
  {
    index: true,
        element: <Signup />
  },
  {
      path: '/signin',
      element: <Signin />
    },
    {
        path: '/signup',
        element: <Signup />
    },
    {
        path: '/verify',
        element: <Verify />
    }
];

export default AppRoutes;
