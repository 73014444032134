
import BasLogo from './img/OLD_basLogo.png';
import logoBas from './img/basLogo.png';
import notSee from './img/notSee.png';
import see from './img/see.png';
import errorImg from './img/img-error.png';
import verificationImg from './img/img-verification.png';
import successImg from './img/img-success.png'; 

export default {
    BasLogo, logoBas, notSee, see, errorImg, verificationImg, successImg
}